import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../Styles/main.css";
import { HashLink } from "react-router-hash-link";
function Navbar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header>
      <a href={"/"}>
        <h1 style={{ color: 'white' }}>Surgimed Jaipur</h1>
      </a>

      <nav ref={navRef}>
        <HashLink smooth to={"/#1"}>
          <a>Romsons</a>
        </HashLink>
        <HashLink smooth to={"/#2"}>
          <a>B.Braun</a>
        </HashLink>
        <HashLink smooth to={"/#3"}>
          <a>Healthium Meditech</a>
        </HashLink>
        <HashLink smooth to={"/#4"}>
          <a>Raman & Weil</a>
        </HashLink>
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
// import { Badge } from "@material-ui/core";
// import { Search, ShoppingCartOutlined } from "@material-ui/icons";
// import React from "react";
// import { HashLink } from "react-router-hash-link";
// import styled from "styled-components";
// import { mobile } from "../responsive";
// import {FaBars, FaTimes} from "react-icons/fa";

// const Container = styled.div`
//   height: 60px;
//   ${mobile({ height: "50px" })}
// `;

// const Wrapper = styled.div`
//   padding: 10px 20px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   ${mobile({ padding: "10px 0px" })}
// `;

// const Left = styled.div`
//   flex: 1;
//   display: flex;
//   align-items: center;
// `;

// const Language = styled.span`
//   font-size: 14px;
//   cursor: pointer;
//   ${mobile({ display: "none" })}
// `;

// const SearchContainer = styled.div`
//   border: 0.5px solid lightgray;
//   display: flex;
//   align-items: center;
//   margin-left: 25px;
//   padding: 5px;
// `;

// const Input = styled.input`
//   border: none;
//   ${mobile({ width: "50px" })}
// `;

// const Center = styled.div`
//   flex: 1;
//   text-align: center;
// `;

// const Logo = styled.h1`
//   font-weight: bold;
//   ${mobile({ fontSize: "24px" })}
// `;
// const Right = styled.div`
//   flex: 1;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   ${mobile({ flex: 2, justifyContent: "center" })}
// `;

// const MenuItem = styled.div`
//   font-size: 14px;
//   cursor: pointer;
//   margin-left: 25px;
//   ${mobile({ fontSize: "12px", marginLeft: "10px" })}
// `;

// const Navbar = () => {
//   return (
//     <Container>
//       <Wrapper>
//  <Left>
//           <Language>EN</Language>
//           {/* <SearchContainer>
//             {/* <Input placeholder="Search" /> */}
//             {/* <Search style={{ color: "gray", fontSize: 16 }} /> */}
//           {/* </SearchContainer> */}
//         </Left>
//         <HashLink smooth to={"/"}>
//           <Center>
//             <Logo>Surgimed Jaipur</Logo>
//           </Center>
//         </HashLink>
//         <Right>
//
//         </Right>
//       </Wrapper>
//     </Container>
//   );
// };

// export default Navbar;
