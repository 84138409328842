import {
  Facebook,
  Instagram,
  MailOutline,
  Phone,
  Pinterest,
  Room,
  Twitter,
} from "@material-ui/icons";
import styled from "styled-components";
import { mobile } from "../responsive";
import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

const App = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open My Custom Dialog
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Greetings from GeeksforGeeks</DialogTitle>
        <DialogContent>
          <DialogContentText>Do you do coding ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  ${mobile({ flexDirection: "column" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Logo = styled.h1``;

const Desc = styled.p`
  margin: 20px 0px;
`;

const SocialContainer = styled.div`
  display: flex;
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

const Center = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ display: "none" })}
`;

const Title = styled.h3`
  margin-bottom: 30px;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  width: 50%;
  margin-bottom: 10px;
`;

const Right = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ backgroundColor: "#fff8f8" })}
`;

const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Payment = styled.img`
  width: 50%;
`;

const Footer = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  return (
    <Container>
      <Left>
        <Logo>Surgimed Jaipur</Logo>
        <Desc>
          We are Surgical products wholesaler located in Jaipur, Rajasthan. We
          deal in product ranges of Romsons, B. Braun, Healthium, Etc.
        </Desc>
        <SocialContainer>
          <SocialIcon color="3B5999">
            <Facebook />
          </SocialIcon>
          <SocialIcon color="E4405F">
            <Instagram />
          </SocialIcon>
          <SocialIcon color="55ACEE">
            <Twitter />
          </SocialIcon>
          <SocialIcon color="E60023">
            <Pinterest />
          </SocialIcon>
        </SocialContainer>
      </Left>
      <Center>
        <Title>Useful Links</Title>
        <List>
          <Button variant="outlined" color="primary" href={"/"}>
            Home
          </Button>
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            About Us
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Surgimed Jaipur</DialogTitle>
            <DialogContent>
              <DialogContentText>
                We are Surgical products wholesaler located in Jaipur,
                Rajasthan. We deal in product ranges of Romsons, B. Braun,
                Healthium, Etc.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Button variant="outlined" color="primary" onClick={handleClickOpen2}>
            Contact Us
          </Button>
          <Dialog open={open2} onClose={handleClose2}>
            <DialogTitle>Surgimed Jaipur</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <ContactItem>
                  <Room style={{ marginRight: "10px" }} /> C-8, New Sanganer Rd,
                  Roop Vihar, Goverdhan Colony, Shiva Colony, Govindpuri,
                  Jaipur, Rajasthan 302019
                </ContactItem>
                <ContactItem>
                  <Phone style={{ marginRight: "10px" }} /> 9828017840
                </ContactItem>
                <ContactItem>
                  <MailOutline style={{ marginRight: "10px" }} />{" "}
                  info@surgimed.com
                </ContactItem>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose2} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </List>
      </Center>
      <Right>
        <Title>Contact</Title>
        <ContactItem>
          <Room style={{ marginRight: "10px" }} /> C-8, New Sanganer Rd, Roop
          Vihar, Goverdhan Colony, Shiva Colony, Govindpuri, Jaipur, Rajasthan
          302019
        </ContactItem>
        <ContactItem>
          <Phone style={{ marginRight: "10px" }} /> 9828017840
        </ContactItem>
        <ContactItem>
          <MailOutline style={{ marginRight: "10px" }} /> info@surgimed.com
        </ContactItem>
        {/* <Payment src="https://i.ibb.co/Qfvn4z6/payment.png" /> */}
      </Right>
    </Container>
  );
};

export default Footer;
