import Product from "./pages/Product";
import Home from "./pages/Home";
import ProductList from "./pages/ProductList";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Cart from "./pages/Cart";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
const App = () => {
  return(
    <Router>
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />
            <Route path="/products/:item" element={<ProductList />} />
          </Route>
        </Routes>
    </Router>
  );
  // return <ProductList/>;
};

export default App;