import React from "react";
import Announcement from "../components/Announcement";
import Categories from "../components/Categories";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Products from "../components/Products";
import Slider from "../components/Slider";
import Healthium from "../components/Healthium";
import Rw from "../components/RW";
import { HashLink } from 'react-router-hash-link';

const Home = () => {
  return (
    <div>
      <Navbar />
      <Slider />
      <section id = "1">
        <Categories />
      </section>
      <section id = "3">
        <Healthium />
      </section>
      <section id = "4">
        <Rw />
      </section>
      <Footer/>
    </div>
  );
};

export default Home;
