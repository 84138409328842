import styled from "styled-components";
import { popularProducts } from "../data";
import Product from "./Product";

function importAll(r) {
	let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
	return images
}


const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const Products = ({item}) => {
  console.log(item)
  const items = {}
  
  items['Anesthesia'] = importAll(require.context('../images/Anesthesia', false, /\.(png|jpe?g|svg)$/));

  items['Cardiac'] = importAll(require.context('../images/Cardiac', false, /\.(png|jpe?g|svg)$/));

  items['Gastro'] = importAll(require.context('../images/Gastro', false, /\.(png|jpe?g|svg)$/));

  items['Surgery'] = importAll(require.context('../images/Surgery', false, /\.(png|jpe?g|svg)$/));

  items['Transfusion'] = importAll(require.context('../images/Transfusion', false, /\.(png|jpe?g|svg)$/));

  items['Urology'] = importAll(require.context('../images/Urology', false, /\.(png|jpe?g|svg)$/));

  items['Advanced Surgery'] = importAll(require.context('../images/Adv_Surgery', false, /\.(png|jpe?g|svg)$/));

  items['Wound Care'] = importAll(require.context('../images/Wound_care', false, /\.(png|jpe?g|svg)$/));

  items['Urology.'] = importAll(require.context('../images/H_Urology', false, /\.(png|jpe?g|svg)$/));

  items['Arthroscopy'] = importAll(require.context('../images/Arthroscopy', false, /\.(png|jpe?g|svg)$/));

  items['Hospital Solutions'] = importAll(require.context('../images/Hospital_p', false, /\.(png|jpe?g|svg)$/));

  items['Industrial Solutions'] = importAll(require.context('../images/Industrial_p', false, /\.(png|jpe?g|svg)$/));

  items['Consumer Solutions'] = importAll(require.context('../images/Consumer_p', false, /\.(png|jpe?g|svg)$/));
  
  return (
    <Container>
      {Object.keys(items[item]).map((key,index) => (
        
        <Product k = {key.replace('-1200x1200.png','')} item={items[item][key]}/>
      ))}
      
    </Container>
  );
};

export default Products;
