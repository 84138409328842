import styled from "styled-components";
import { categories } from "../data";
import { mobile } from "../responsive";
import CategoryItem from "./CategoryItem";

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  ${mobile({ padding: "0px", flexDirection:"column" })}

`;

function importAll(r) {
	let images = [];
  r.keys().forEach((item, index) => { images[index] = r(item)});
	return images
}

const Category_Images = importAll(require.context('../images/Romsons', false, /\.(png|jpe?g|svg)$/));

{categories.map((item, index) => (
  item.img = Category_Images[index],
  console.log(Category_Images[index])

))}

const Categories = () => {
  return (
    <div>
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <h1> Romsons Products </h1>
      </div>
      <Container>
      
      {categories.map((item) => (
        <CategoryItem item={item} key={item.id} />
      ))}
    </Container>
    </div>
    
  );
};

export default Categories;
