export const sliderItems = [
    {
      id: 1,
      img: "https://i.ibb.co/XsdmR2c/1.png",
      title: "Disposable Medical Devices",
      desc: "Romsons are the leading manufacturers of disposable medical devices like surgical gloves,cannula,uro bag,uro meter,Feedy and many more.",
      bg: "48d1cc",
      link: "https://www.romsons.com/",
    },
    {
      id: 2,
      img: "https://i.ibb.co/DG69bQ4/2.png",
      title: "Sharing Expertise",
      desc: "B. Braun-Group develops effective solutions and guiding standards for the healthcare system through constructive dialogue with our customers and partners",
      bg: "e9967a",
      link: "https://www.bbraun.co.in/en.html",
    },
    {
      id: 3,
      img: "https://i.ibb.co/cXFnLLV/3.png",
      title: "Access to precision medtech for every patient",
      desc: "A global medtech company focused on products used in surgical, post-surgical and chronic care.",
      bg: "d8bfd8",
      link: "https://www.healthiummedtech.com/",
    },
    {
      id: 4,
      img: "https://i.ibb.co/cXFnLLV/3.png",
      title: "Excellence in Infection Control",
      desc: " Raman & Weil Pvt. Ltd. India, pioneers in the field of hi-tech disinfectants and antiseptics for use in hospitals, clinics, medical facilities, and pharmaceutical/ industrial manufacturing units. ",
      bg: "eee8aa",
      link: "https://ramanweil.com/",
    },
  ];

  export const categories = [
    {
      id: 1,
      img: "https://images.pexels.com/photos/5886041/pexels-photo-5886041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Anesthesia",
    },
    {
      id: 2,
      img: "https://images.pexels.com/photos/2983464/pexels-photo-2983464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Cardiac",
    },
    {
      id: 3,
      img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      title: "Gastro",
    },
    {
      id: 4,
      img: "https://images.pexels.com/photos/5886041/pexels-photo-5886041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Surgery",
    },
    {
      id: 5,
      img: "https://images.pexels.com/photos/2983464/pexels-photo-2983464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Transfusion",
    },
    {
      id: 6,
      img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      title: "Urology",
    },
  ];
  export const healthium = [
    {
      id: 1,
      img: "https://images.pexels.com/photos/5886041/pexels-photo-5886041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Advanced Surgery",
    },
    {
      id: 2,
      img: "https://images.pexels.com/photos/2983464/pexels-photo-2983464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Wound Care",
    },
    {
      id: 3,
      img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      title: "Urology.",
    },
    {
      id: 4,
      img: "https://images.pexels.com/photos/5886041/pexels-photo-5886041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Arthroscopy",
    },
  ];

  export const rw = [
    {
      id: 1,
      img: "https://images.pexels.com/photos/5886041/pexels-photo-5886041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Hospital Solutions",
    },
    {
      id: 2,
      img: "https://images.pexels.com/photos/2983464/pexels-photo-2983464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "Industrial Solutions",
    },
    {
      id: 3,
      img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      title: "Consumer Solutions",
    },
  ];
  