import styled from "styled-components";
import { healthium } from "../data";
import { mobile } from "../responsive";
import HealthiumItem from "./HealthiumItem";

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  ${mobile({ padding: "0px", flexDirection:"column" })}

`;

function importAll(r) {
	let images = [];
  r.keys().forEach((item, index) => { images[index] = r(item)});
	return images
}

const Category_Images = importAll(require.context('../images/Healthium', false, /\.(png|jpe?g|svg)$/));

{healthium.map((item, index) => (
  item.img = Category_Images[index],
  console.log(Category_Images[index])

))}

const Healthium = () => {
  return (
    <div>
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <h1> Healthium Products </h1>
      </div>
      <Container>
      
      {healthium.map((item) => (
        <HealthiumItem item={item} key={item.id} />
      ))}
    </Container>
    </div>
    
  );
};

export default Healthium;
